import VerifyCode from "@/components/utils/VerifyCode.vue";  //数字验证码
import Slider from "@/components/utils/Slider.vue";  //滑块验证码
import loginUtil,{ILoginForm,ILoginDataObj} from "@/views/login/loginUtil";
import {getCurrentInstance, reactive, toRefs, onBeforeMount, onMounted, defineComponent, nextTick, inject, computed} from 'vue';
import {useStore} from "vuex";
let loginForm:ILoginForm={
    usercode: "",
    password: "",
    verifyCode: ""
}
export default defineComponent({
    name: "Login",
    setup(props,context){
        let app = inject('app') as any;
        const appInstant = getCurrentInstance();
        const {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        let store = useStore();
        proxy.i18n.global.locale.value = localStorage.getItem('currentLang')?localStorage.getItem('currentLang'):'zh-CN';
        let dataObj:ILoginDataObj=reactive<ILoginDataObj>({
            refMap:new Map(),
            sliderStatus: false,//滑块状态（滑块是否验证成功）
            yzmType:0,//验证码种类。0：滑块验证 1：数字验证
            backgroundImage:'',//登录页背景图片（onMounted里面会改变）
            useYzm: true,//登录是否启用验证码
            loginForm:loginForm,
            loginFormRules:utils.UtilPub.commonFormValidRule([{usercode:proxy.$t('login.usercodePlaceholder')},{password:proxy.$t('login.passwordPlaceholder')}]),
            fullscreenLoading:false,//点击登录按钮，为页面增加遮罩层，该变量控制的是遮罩层的显示/影藏状态
            loginMsg:'',//登录页面欢迎标语
            otherParams:{
                isLoading:false,
                canRegister:false,//是否启用注册功能
                needMultiLanguage:false,
                currentLang:localStorage.getItem('currentLang')?localStorage.getItem('currentLang'):'zh-CN'
            }
        })
        let loginUtilInst: loginUtil=new loginUtil(proxy,appInstant,dataObj);
        onBeforeMount(()=>{

        })
        onMounted( async()=>{
            await nextTick(async()=>{
                //系统建议用谷歌浏览器，这里检查是否为谷歌浏览器，给予相应提示
                loginUtilInst.checkBrows();
                //如果是退出登录，proxy.$socket就不会是undefined，那么需要关闭websocket，因为在登录成功的方法里面对$socket进行了创建
                if (proxy.$socket) proxy.$socket.close();
                //清空sessionStore
                sessionStorage.clear();
                //关闭掉所有的tabs
                await store.dispatch("closeAllVisitedTabsView");
                sessionStorage.removeItem('userLayerData');
                //光标定位到第一个文本框
                dataObj.refMap.get('usercodeRef').focus();
                //进入登录页面，请求后台，获取图片资源，设置页面背景
                await loginUtilInst.loginInit(dataObj);

                //登录界面是否要动画，引入不同css(不能放到onBeforeMount，因为utils可能为空)
               if(utils.Tools.isAnimationOpen('loginAnimation')){
                   document.documentElement.style.setProperty('--login-before', proxy.$t('login.beforeContent'));
                   document.documentElement.style.setProperty('--login-after', proxy.$t('login.afterContent'));
                   import('../../style/login/login.scss').then(() => { });
               }else{
                   import('../../style/login/loginNoAnimation.scss');//可以不要then，加上也可以
               }
            })
        })
        const selectOnChange=(newValue:string,selectId:string)=>{
            proxy.i18n.global.locale.value = newValue;
            localStorage.setItem('currentLang',newValue);
            app.helpParam++
        }
        const loginClass=computed( ()=>{
            //wc，我试着用utils.Tools.isAnimationOpen('loginAnimation')去取，结果报错，估计这里执行比较靠前吧，记得把loginAnimationFlag设成1吧，好像设成0没啥用，必须设成1才行
            let loginAnimationFlag:number = localStorage.getItem('loginAnimation')?parseInt(localStorage.getItem('loginAnimation') as string):1;
            return {
                login: true,
                animate__animated: loginAnimationFlag==0,//要启用登录动画就不能用animate动画，当没有启用登录动画的时候就启用animate动画
                animate__fadeInLeft: loginAnimationFlag==0
            };

        })
        return { //必须返回 模板中才能使用
            ...toRefs(dataObj),loginUtilInst,selectOnChange,loginClass
        }
    },
    components: {VerifyCode,Slider}
});